interface Menu {
  name: string;
  path: string;
  icon: string;
}
export const GlobalMenus: Menu[] = [
  {
    name: '홈',
    path: '/',
    icon: '🏠',
  },
  {
    name: '카테고리',
    path: '/categories',
    icon: '📦',
  },
  {
    name: '태그',
    path: '/tags',
    icon: '🔖',
  },
  {
    name: 'About',
    path: '/about',
    icon: '🙋‍♂️',
  },
];
