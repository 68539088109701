import { AmplitudeClient } from 'amplitude-js';
import { isProduction } from './env';

const API_KEY = isProduction ? '3db9c85582c604d9f336ef03aafd6e13' : '84150f88d2794bb9512dddf5ccff192a';
let amplitudeClient: AmplitudeClient | null = null;
let initialized = false;

export const getAmplitudeClient = (): Promise<AmplitudeClient | null> => {
  return new Promise(resolve => {
    if (initialized) {
      resolve(amplitudeClient);
      return;
    }

    require('amplitude-js').init(API_KEY, 'unknown', {}, async (client: AmplitudeClient) => {
      initialized = true;
      amplitudeClient = client;
      resolve(client);
    });
  });
};
