import { useStaticQuery, graphql } from 'gatsby';
import { ImageSharp } from 'models/image';
import { Author, Social } from 'models/site';

interface Profile {
  avatar: ImageSharp;
  site: {
    siteMetadata: {
      description: string;
      author: Author;
      social: Social;
    };
  };
}
export const useProfileInfo = () => {
  const data = useStaticQuery<Profile>(graphql`
    query {
      avatar: file(absolutePath: { regex: "/profile.jpg/" }) {
        childImageSharp {
          fixed(width: 512, height: 512, cropFocus: CENTER, quality: 100) {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          description
          author {
            name
            summary
            email
          }
          social {
            github
            linkedin
            inflearn
            rss
          }
        }
      }
    }
  `);

  return data;
};
