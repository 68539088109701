import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>(({ spacing, typography }) => ({
  root: {
    margin: 0,
    textAlign: 'center',
  },
  description: {
    display: 'block',
    marginTop: spacing(1),
    fontSize: typography.body2.fontSize,
    fontWeight: typography.caption.fontWeight,
    color: typography.caption.color,
  },
}));

interface Props {
  title: string;
  description: string;
}
const LayoutTitle = ({ title, description }: Props) => {
  const styles = useStyles();
  return (
    <h1 className={styles.root}>
      {title}
      <small className={styles.description}>{description}</small>
    </h1>
  );
};

export default LayoutTitle;
