import React, { ComponentProps, useCallback, useState } from 'react';
import { navigate } from 'gatsby';
import SearchInput from 'components/Forms/SearchInput';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import * as Icon from 'react-feather';
import { Logger } from 'utils/loggers';

interface SearchDialogProps extends Omit<ComponentProps<typeof Dialog>, 'onSubmit'> {
  onSubmit?: (keyword: string) => void;
}
const SearchDialog = ({ onSubmit, ...props }: SearchDialogProps) => {
  return (
    <Dialog fullWidth={true} {...props}>
      <DialogTitle>포스팅 검색하기</DialogTitle>
      <DialogContent>
        <SearchInput onSubmit={onSubmit} autoFocus={true} />
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

interface Props {
  logger?: Logger;
}
const SearchField = ({ logger }: Props) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleSearchSubmit = useCallback(
    (keyword: string) => {
      setOpenDialog(false);
      logger?.event('search', 'search', { keyword });
      navigate(`/search-results?q=${keyword}`);
    },
    [logger]
  );

  const handleSearchDialogOpenButton = useCallback(() => {
    setOpenDialog(true);
  }, []);

  const handleSearchDialogClose = useCallback(() => {
    setOpenDialog(false);
  }, []);

  return (
    <>
      <Box display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block' }}>
        <SearchInput onSubmit={handleSearchSubmit} />
      </Box>
      <Box display={{ xs: 'block', sm: 'block', md: 'block', lg: 'none' }}>
        <IconButton onClick={handleSearchDialogOpenButton}>
          <Icon.Search />
        </IconButton>
        <SearchDialog open={openDialog} onClose={handleSearchDialogClose} onSubmit={handleSearchSubmit} />
      </Box>
    </>
  );
};

export default SearchField;
