import React, { useState, useCallback, useMemo } from 'react';
import {
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  useMediaQuery,
  useTheme,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { GlobalMenus } from 'models/menu';
import Emoji from 'components/Utils/Emoji';
import * as Icon from 'react-feather';
import { Link } from 'gatsby';
import MySocials from 'components/Utils/MySocials';

const useStyles = makeStyles<Theme>(({ palette, spacing }) => ({
  socials: {
    margin: `${spacing(2)}px 0 ${spacing(1)}px`,
  },
  list: {
    minWidth: 300,
    '& a': {
      textDecoration: 'none',
      color: palette.text.primary,
    },
  },
}));

interface Props {
  onChange?: (state: boolean) => void;
  onClickMenu?: (menuName: string) => void;
  onClickExternalLink?: (target: string) => void;
}
const DrawerMenu = ({ onClickMenu, onChange }: Props) => {
  const styles = useStyles();
  const [open, setOpen] = useState(false);
  const { breakpoints } = useTheme();
  const matches = useMediaQuery(breakpoints.up('lg'));
  const iconSize = useMemo(() => (matches ? 30 : 20), [matches]);

  const handleClick = useCallback(() => {
    setOpen(state => !state);
    onChange?.(true);
  }, [setOpen, onChange]);

  return (
    <>
      <IconButton onClick={handleClick} aria-label="menu">
        <Icon.Menu size={iconSize} />
      </IconButton>
      <SwipeableDrawer
        open={open}
        onClose={() => {
          setOpen(false);
          onChange?.(false);
        }}
        onOpen={() => {
          setOpen(true);
          onChange?.(true);
        }}
      >
        <MySocials className={styles.socials} />
        <List className={styles.list}>
          {GlobalMenus.map(menu => (
            <Link key={menu.path} to={menu.path} onClick={() => onClickMenu?.(menu.name)}>
              <ListItem button key={menu.path}>
                <ListItemIcon>
                  <Emoji emoji={menu.icon} name={menu.name} />
                </ListItemIcon>
                <ListItemText>{menu.name}</ListItemText>
              </ListItem>
            </Link>
          ))}
        </List>
      </SwipeableDrawer>
    </>
  );
};

export default DrawerMenu;
