import React from 'react';
import { Container, Grid, Typography, makeStyles, Theme, Divider } from '@material-ui/core';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { useLogoData } from 'hooks/useLogoData';
import { useSiteMetadata } from 'hooks/useSiteMetadata';
import { GlobalMenus } from 'models/menu';
import { generateLogger } from 'utils/loggers';

const logger = generateLogger('global_footer');
const handleLogoClick = () => {
  logger.click('click_logo');
};
const handleMenuClick = (menu: string) => () => {
  logger.click('click_menu', { menu });
};

const useStyles = makeStyles<Theme>(({ palette, spacing, breakpoints }) => ({
  root: {
    backgroundColor: palette.background.paper,
  },
  wrapper: {
    padding: `${spacing(5)}px 0 ${spacing(10)}px`,
  },
  logo: {
    display: 'block',
    marginBottom: spacing(1),
    width: 100,
  },
  menu: {
    margin: 0,
    padding: 0,
    justifyContent: 'center',
    [breakpoints.up('lg')]: {
      justifyContent: 'flex-start',
    },
    '& li': {
      margin: spacing(1),
      listStyle: 'none',
      [breakpoints.up('lg')]: {
        margin: 0,
        marginRight: spacing(2),
      },
    },
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
      '&.active': {
        color: palette.primary.main,
      },
      '&:hover': {
        textDecoration: 'underline',
      },
      '&:visited': {
        color: 'inherit',
      },
    },
  },
  rights: {
    alignItems: 'center',
    marginTop: spacing(3),
    [breakpoints.up('lg')]: {
      alignItems: 'flex-end',
      marginTop: 0,
    },
    '& a': {
      color: palette.primary.main,
      textDecoration: 'none',
    },
  },
}));

const Footer = () => {
  const styles = useStyles();
  const {
    logoText: {
      childImageSharp: { fluid: logoUrl },
    },
  } = useLogoData();
  const { author } = useSiteMetadata();

  return (
    <Container component="footer" className={styles.root}>
      <Divider />
      <Grid container spacing={0} className={styles.wrapper}>
        <Grid component="ul" item xs={12} md container className={styles.menu}>
          {GlobalMenus.map(menu => (
            <Grid component="li" item key={menu.path}>
              <Link to={menu.path} onClick={handleMenuClick(menu.name)}>
                {menu.name}
              </Link>
            </Grid>
          ))}
        </Grid>
        <Grid item container xs={12} md direction="column" className={styles.rights}>
          <Link to="/" className={styles.logo} onClick={handleLogoClick}>
            <Image fluid={logoUrl} alt="로고" />
          </Link>
          <Typography variant="caption" component="p" color="textSecondary">
            © 2019 {author.name} Powered by{' '}
            <a href="https://www.gatsbyjs.org/" className={styles.inlineLink}>
              Gatsby
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
