import React, { useCallback, useMemo } from 'react';
import * as Icon from 'react-feather';
import { useProfileInfo } from 'hooks/useProfileInfo';
import { Box, BoxProps, IconButton, Tooltip, TooltipProps } from '@material-ui/core';

interface Props extends Omit<BoxProps, 'onClick'> {
  size?: number;
  tooltipPlacement?: TooltipProps['placement'];
  onClick?: (name: string) => void;
}

const MySocials = ({ size = 20, tooltipPlacement = 'top', onClick, ...rest }: Props) => {
  const {
    site: {
      siteMetadata: { author, social },
    },
  } = useProfileInfo();

  const socialData = useMemo(
    () => [
      {
        link: `mailto:${author.email}`,
        icon: <Icon.Mail size={size} />,
        name: 'email',
      },
      {
        link: social.github,
        icon: <Icon.GitHub size={size} />,
        name: 'github',
      },
      {
        link: social.linkedin,
        icon: <Icon.Linkedin size={size} />,
        name: 'linkedin',
      },
      {
        link: social.inflearn,
        icon: <Icon.Feather size={size} />,
        name: 'Inflearn',
      },
      {
        link: social.rss,
        icon: <Icon.Rss size={size} />,
        name: 'rss',
      },
    ],
    [social, author, size]
  );

  const handleLinkClick = useCallback(
    (name: string) => {
      return () => onClick?.(name);
    },
    [onClick]
  );

  return (
    <Box display="flex" justifyContent="center" {...rest}>
      {socialData.map(({ icon, link, name }) => (
        <IconButton key={link} href={link} target="_blank" onClick={handleLinkClick(name)}>
          <Tooltip title={name} placement={tooltipPlacement}>
            {icon}
          </Tooltip>
        </IconButton>
      ))}
    </Box>
  );
};

export default MySocials;
