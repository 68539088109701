import React, { useCallback, ChangeEvent, useState, useEffect, FormEvent } from 'react';
import { InputAdornment, makeStyles, Theme, Chip, IconButton, Input } from '@material-ui/core';
import { useDebounce } from 'hooks/useDebounce';
import * as Icon from 'react-feather';

const useStyles = makeStyles<Theme, { hasShortcuts: boolean }>(({ spacing }) => ({
  textField: ({ hasShortcuts }) => ({
    width: '100%',
    marginBottom: hasShortcuts ? spacing(2) : 0,
  }),
  shortcuts: {
    margin: 0,
    padding: 0,
  },
  chip: {
    marginRight: spacing(1),
    marginBottom: spacing(0.5),
    lineHeight: 1.1,
  },
}));

interface Props {
  defaultValue?: string;
  shortcuts?: string[];
  placeholder?: string;
  autoFocus?: boolean;
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
  onClickShortcut?: (value: string) => void;
}
const SearchInput = ({
  defaultValue = '',
  shortcuts = [],
  placeholder,
  autoFocus = false,
  onChange = () => {},
  onClickShortcut,
  onSubmit,
}: Props) => {
  const styles = useStyles({
    hasShortcuts: shortcuts.length > 0,
  });
  const [keyword, setKeyword] = useState(defaultValue);
  const emitChangeEvent = useDebounce(onChange, 500);
  const handleChangeValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setKeyword(value);
    },
    [setKeyword]
  );
  const handleClickCategoryShortcut = useCallback(
    (category: string) => {
      return () => {
        setKeyword(category);
        onClickShortcut?.(category);
      };
    },
    [setKeyword, onClickShortcut]
  );

  const handleRemoveButtonClick = useCallback(() => {
    setKeyword('');
  }, []);

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onSubmit?.(keyword);
    },
    [onSubmit, keyword]
  );

  useEffect(() => {
    emitChangeEvent(keyword);
  }, [keyword, emitChangeEvent]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Input
          placeholder={placeholder}
          startAdornment={
            <InputAdornment position="start">
              <Icon.Search strokeWidth={1} />
            </InputAdornment>
          }
          endAdornment={
            keyword.length > 0 && (
              <InputAdornment position="end">
                <IconButton onClick={handleRemoveButtonClick}>
                  <Icon.X />
                </IconButton>
              </InputAdornment>
            )
          }
          value={keyword}
          onChange={handleChangeValue}
          className={styles.textField}
          autoFocus={autoFocus}
          type="search"
        />
      </form>
      {shortcuts && (
        <ul className={styles.shortcuts}>
          {shortcuts.map(v => (
            <Chip
              key={v}
              size="small"
              label={v}
              variant="outlined"
              className={styles.chip}
              onClick={handleClickCategoryShortcut(v)}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchInput;
