import { useEffect, useRef, useCallback } from 'react';
import debounce from 'lodash/debounce';

export const useDebounce = <F extends (...args: any[]) => any>(callback: F, wait: number) => {
  const savedCallback = useRef<F>();
  const handleDebounceCall = useCallback((...args: any[]) => {
    savedCallback.current!(...args);
  }, []);

  useEffect(() => {
    savedCallback.current = debounce(callback, wait);
  }, [callback, wait]);

  return handleDebounceCall as F;
};
