import React from 'react';
import { Box, Container, Grid, makeStyles, Theme } from '@material-ui/core';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { generateLogger } from 'utils/loggers';
import { useLogoData } from 'hooks/useLogoData';
import DrawerMenu from './DrawerMenu';
import SearchField from './SearchField';

const logger = generateLogger('global_header');
const handleLogoClick = () => {
  logger.click('click_logo');
};
const handleMenuClick = (name: string) => {
  logger.click(`click_menu`, { name });
};
const handleDrawerStateChange = (open: boolean) => {
  if (open) {
    logger.impression('open_global_menu');
  } else {
    logger.impression('close_global_menu');
  }
};

const useStyles = makeStyles<Theme>(({ spacing, palette, breakpoints, typography }) => ({
  root: {
    position: 'fixed',
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    width: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 300,
    backdropFilter: 'blur(3px)',
  },
  drawerButton: {
    position: 'absolute',
    left: spacing(1),
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  wrapper: {
    justifyContent: 'center',
    padding: `${spacing(2)}px 0`,
  },
  logo: {
    textAlign: 'center',
    width: 130,
  },
  list: {
    margin: 0,
    padding: 0,
  },
  item: {
    margin: `0 ${spacing(2)}px`,
    fontSize: typography.caption.fontSize,
    listStyle: 'none',
    [breakpoints.up('lg')]: {
      marginLeft: 0,
      marginRight: spacing(4),
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&.active': {
      color: palette.primary.main,
    },
    '&:visited': {
      color: 'inherit',
    },
  },
}));

const Header = () => {
  const styles = useStyles();
  const {
    logoText: {
      childImageSharp: { fluid: logoUrl },
    },
  } = useLogoData();

  return (
    <Container component="header" className={styles.root}>
      <Box position="relative">
        <Grid container alignItems="center" justify="space-between">
          <Grid item lg={2}>
            <DrawerMenu onChange={handleDrawerStateChange} onClickMenu={handleMenuClick} />
          </Grid>
          <Grid item className={styles.wrapper}>
            <Grid item xs lg={'auto'} className={styles.logo}>
              <Link to="/" onClick={handleLogoClick}>
                <Image fluid={logoUrl} alt="로고" />
              </Link>
            </Grid>
            <Grid />
          </Grid>
          <Grid item lg={2}>
            <SearchField logger={logger} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Header;
