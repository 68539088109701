import React from 'react';
import { Grid, Typography, makeStyles, Theme } from '@material-ui/core';
import { useProfileInfo } from 'hooks/useProfileInfo';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import shape from '@material-ui/core/styles/shape';
import { generateLogger } from 'utils/loggers';
import MySocials from './Utils/MySocials';

const logger = generateLogger('profile_card');
const handleLinkClick = (target: string) => () => {
  logger.click(`click_external_link`, { target });
};
const handleNameClick = () => {
  logger.click('click_name');
};

const useStyles = makeStyles<Theme>(({ spacing, palette, breakpoints }) => ({
  root: {
    padding: spacing(2),
  },
  imageWrapper: {
    [breakpoints.up('lg')]: {
      marginRight: spacing(2),
    },
  },
  image: {
    width: 128,
    height: 128,
    borderRadius: shape.borderRadius,
    [breakpoints.down('lg')]: {
      margin: '0 auto',
    },
  },
  infoWrapper: {
    [breakpoints.up('lg')]: {
      marginRight: spacing(2),
    },
  },
  name: {
    color: palette.primary.main,
    textAlign: 'center',
    textDecoration: 'none',
    [breakpoints.up('lg')]: {
      textAlign: 'left',
    },
  },
  summary: {
    textAlign: 'center',
    marginBottom: spacing(2),
    [breakpoints.up('lg')]: {
      textAlign: 'left',
    },
  },
}));

const Profile = () => {
  const styles = useStyles();
  const {
    avatar: {
      childImageSharp: { fluid: avatar },
    },
    site: {
      siteMetadata: { author, description },
    },
  } = useProfileInfo();

  return (
    <Grid container className={styles.root} justify="center">
      <Grid component="a" item md={12} lg="auto" className={styles.imageWrapper} href={avatar?.src} target="_blank">
        <Image fluid={avatar} alt={author.name} className={styles.image} />
      </Grid>
      <Grid item md={12} lg="auto" className={styles.infoWrapper}>
        <Link to="/about" className={styles.name} onClick={handleNameClick}>
          <Typography variant="h6">{author.name}</Typography>
        </Link>
        <Typography gutterBottom variant="body2" className={styles.summary}>
          {author.summary}
        </Typography>
        <Typography gutterBottom variant="caption">
          {description}
        </Typography>
      </Grid>
      <Grid item>
        <MySocials onClick={handleLinkClick} />
      </Grid>
    </Grid>
  );
};

export default Profile;
