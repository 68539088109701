import React, { ReactNode, useMemo } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Profile from 'components/Profile';
import { Box, BoxProps, Container, Grid, makeStyles, Theme } from '@material-ui/core';
import LayoutTitle from './LayoutTitle';

interface Props extends BoxProps {
  title?: ReactNode;
  header?: boolean;
  footer?: boolean;
  profile?: boolean;
  left?: ReactNode;
  right?: ReactNode;
  rightSticky?: boolean;
  leftSticky?: boolean;
  children: ReactNode;
  noPadding?: boolean;
}

const useStyles = makeStyles<Theme, { noPadding: boolean }>(({ breakpoints, spacing }) => ({
  container: props => ({
    paddingTop: props.noPadding ? 0 : spacing(10),
    paddingBottom: props.noPadding ? 0 : spacing(6),
  }),
  title: {
    marginBottom: spacing(3),
  },
  left: {
    [breakpoints.down('md')]: {
      order: 2,
    },
  },
  center: {
    [breakpoints.down('md')]: {
      order: 1,
    },
  },
  right: {
    [breakpoints.down('md')]: {
      order: 3,
    },
  },
  sticky: {
    alignSelf: 'flex-start',
    position: 'sticky',
    top: spacing(9),
  },
  profile: {
    marginTop: spacing(8),
  },
}));

const Layout = ({
  title,
  header = true,
  footer = true,
  profile = true,
  children,
  left,
  right,
  leftSticky = false,
  rightSticky = false,
  noPadding = false,
  ...rest
}: Props) => {
  const styles = useStyles({ noPadding });

  const desktopCenterGridSize = useMemo(() => {
    if (left && right) {
      return 6;
    } else if (left || right) {
      return 8;
    } else {
      return 12;
    }
  }, [left, right]);

  return (
    <Box {...rest}>
      {header && <Header />}
      <Container component="main" className={styles.container}>
        {title && (
          <Grid container className={styles.title}>
            <Grid item xs>
              {title}
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2}>
          {left && (
            <Grid item xs={12} lg={right ? 3 : 4} className={`${styles.left} ${leftSticky && styles.sticky}`}>
              {left}
            </Grid>
          )}
          <Grid item xs={12} lg={desktopCenterGridSize} className={styles.center}>
            {children}
          </Grid>
          {right && (
            <Grid item xs={12} lg={left ? 3 : 4} className={`${styles.right} ${rightSticky && styles.sticky}`}>
              {right}
            </Grid>
          )}
        </Grid>
      </Container>
      {profile && (
        <div className={styles.profile}>
          <Profile />
        </div>
      )}
      {footer && <Footer />}
    </Box>
  );
};

Layout.Title = LayoutTitle;

export default Layout;
